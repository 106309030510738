<div class="d-flex justify-content-center align-items-center mb-4">
  <div class="btn btn-outline-secondary flex-grow-1" (click)="saveType('house')"
       [class.active]="pickupAddress.type == 'house'">
      <div class="text-center">
          <i class="fa fa-2x fa-home"></i>
      </div>
      {{'House' | translate}}
  </div>
  <div class="btn btn-outline-secondary  flex-grow-1 mx-4" (click)="saveType('building')"
       [class.active]="pickupAddress.type == 'building'">
      <div class="text-center">
          <i class="far  fa-2x fa-building"></i>
      </div>
      {{'Building' | translate}}
  </div>
  <div class="btn btn-outline-secondary   flex-grow-1" (click)="saveType('work')"
       [class.active]="pickupAddress.type == 'work'">
      <div class="text-center">
          <i class="fa fa-2x fa-briefcase"></i>
      </div>
      {{'Work' | translate}}
  </div>

</div>
<div class="d-flex justify-content-between mb-2" *ngIf='allowShipping'>
  <span class="">
      {{'Country'| translate}}    <span class=" px-1" *ngIf="country" (click)="openCountry()">{{country | iknLocalized}}</span>
  </span>
  <span class=" px-1 color-primary" *ngIf="country" (click)="openCountry()">{{'Change Country' | translate}}</span>
  <span class=" px-1 color-primary" *ngIf="!country" (click)="openCountry()">{{'Choose your country' | translate}}</span>
</div>


<div class="d-flex justify-content-between mb-2" *ngIf='isShippingMode'>
  <span class="">
      {{'City'| translate}}
      <span class=" px-1" *ngIf="country" (click)="openCity()">{{cart.orderInfo.pickupAddress.governateName | iknLocalized}}</span>
  </span>
  <span class=" px-1 color-primary" *ngIf="country" (click)="openCity()">{{'Change City' | translate}}</span>
  <span class=" px-1 color-primary" *ngIf="!country" (click)="openCity()">{{'Choose your city' | translate}}</span>
</div>
<div class="input-wrapper">
  <label class="m-0 f-bold">{{'Area' | translate}}*:</label>
  <div class="d-flex justify-content-between mb-2">

      {{area?.name || pickupAddress.area | iknLocalized}}
      <app-area [(areaId)]='pickupAddress.areaId'
                [(areaName)]='pickupAddress.area'
                [(areaObj)]='area'
                [saveToCart]='false'
                (areaChanged)='onAreaSelected($event)'></app-area>
      <div *ngIf="submitted && !area" class="invalid-feedback">  {{'Choose Your Delivery Location' | translate}}</div>
  </div>
</div>
<div class="w-100 position-relative" *ngIf='!isShippingMode'>
  <div style=" height: 150px;max-height: 200px;" class="position-relative border rounded shadow" routerLink="/set-location">
      <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="17"
               style="height: 100%;width:100%" #agMap [attr.disabled]="true" [disableDefaultUI]="true" [draggableCursor]="false"
               [mapDraggable]="false">
          <agm-marker [iconUrl]="this.pickupIcon.icon" [latitude]="latitude"
                      [longitude]="longitude">
          </agm-marker>
      </agm-map>
  </div>
</div>
<div style="margin-top:25px"></div>
<form #frmAddress="ngForm" [formGroup]="addressForm">

  <div class="input-group">
      <div class="input-wrapper">
          <label class="m-0 f-bold">{{'Block' | translate}}*</label>
          <input type="text" (change)="storeAddress()" class="im im-flat" placeholder="{{'Block' | translate}} *"
                 formControlName="blockNo" [(ngModel)]="pickupAddress.blockNo" required>
          <div *ngIf="submitted && f.blockNo.errors" class="invalid-feedback">
              <div *ngIf="f.blockNo.errors.required">{{'Block is required'|translate}}</div>
          </div>
      </div>
      <div class="input-wrapper">
          <label class="m-0 f-bold">{{'Street' | translate}}*</label>
          <input type="text" (change)="storeAddress()" class="" placeholder="{{'Enter street name/number' | translate}} *"
                 formControlName="street" [(ngModel)]="pickupAddress.street" required>
          <div *ngIf="submitted && f.street.errors" class="invalid-feedback">
              <div *ngIf="f.street.errors.required">{{'Street is required'|translate}}</div>
          </div>
      </div>
      <div class="input-wrapper">
          <label class="m-0 f-bold">{{'Avenue' | translate}}</label>
          <input type="text" (change)="storeAddress()" class="im im-flat"
                 placeholder="{{'Avenue number (optional)' | translate}}" formControlName="avenue"
                 [(ngModel)]="pickupAddress.avenue">
          <div *ngIf="submitted && f.avenue.errors" class="invalid-feedback">
              <div *ngIf="f.avenue.errors.required">{{'Avenue is required'|translate}}</div>
          </div>
      </div>
      <div class="input-wrapper" *ngIf="pickupAddress.type == 'house'">
          <label class="m-0 f-bold">{{'House' | translate}}*</label>
          <input type="text" (change)="storeAddress()" class="" placeholder="{{'Enter house number' | translate}} *"
                 formControlName="buildingNo" [(ngModel)]="pickupAddress.buildingNo" required>
          <div *ngIf="submitted && f.buildingNo.errors" class="invalid-feedback">
              <div *ngIf="f.buildingNo.errors.required">{{'House number is required'|translate}}</div>
          </div>
      </div>
      <div class="input-wrapper" *ngIf="pickupAddress.type != 'house'">
          <label class="m-0 f-bold">{{'Building Number' | translate}}*</label>
          <input type="text" (change)="storeAddress()" class="" placeholder="{{'Enter building number' | translate}} *"
                 formControlName="buildingNo" [(ngModel)]="pickupAddress.buildingNo" required>
          <div *ngIf="submitted && f.buildingNo.errors" class="invalid-feedback">
              <div *ngIf="f.buildingNo.errors.required">{{'Building Number is required'|translate}}</div>
          </div>
      </div>
      <div class="input-wrapper" *ngIf="pickupAddress.type != 'house'">
          <label class="m-0 f-bold">{{'Floor Number' | translate}}*</label>
          <input type="text" (change)="storeAddress()" class="" placeholder="{{'Enter floor number' | translate}}"
                 formControlName="floorNo" [(ngModel)]="pickupAddress.floorNo">
          <div *ngIf="submitted && f.floorNo.errors" class="invalid-feedback">
              <div *ngIf="f.floorNo.errors.required">{{'Floor Number is required'|translate}}</div>
          </div>
      </div>
      <div class="input-wrapper" *ngIf="pickupAddress.type == 'building'">
          <label class="m-0 f-bold">{{'Flat Number' | translate}}</label>
          <input type="text" (change)="storeAddress()" class="" placeholder="{{'Enter flat number' | translate}}"
                 formControlName="flatNo" [(ngModel)]="pickupAddress.flatNo">
          <div *ngIf="submitted && f.flatNo.errors" class="invalid-feedback">
              <div *ngIf="f.flatNo.errors.required">{{'Flat Number is required'|translate}}*</div>
          </div>
      </div>
      <div class="input-wrapper" *ngIf="pickupAddress.type == 'work'">
          <label class="m-0 f-bold">{{'Office Number' | translate}}*</label>
          <input type="text" (change)="storeAddress()" class="" placeholder="{{'Enter floor number' | translate}}"
                 formControlName="flatNo" [(ngModel)]="pickupAddress.flatNo">
          <div *ngIf="submitted && f.flatNo.errors" class="invalid-feedback">
              <div *ngIf="f.flatNo.errors.required">{{'Office Number is required'|translate}}</div>
          </div>
      </div>
      <div class="input-wrapper">
          <label class="m-0 f-bold">{{'Phone Number' | translate}}*</label>
          <ngx-intl-tel-input [cssClass]="'phone-input'" [preferredCountries]="preferredCountries"
                              [enableAutoCountrySelect]="false" [enablePlaceholder]="true"
                              [searchCountryPlaceholder]="'Search Country' | translate" [searchCountryFlag]="true"
                              [searchCountryField]="[SearchCountryField.All]" [selectFirstCountry]="false"
                              [selectedCountryISO]="selectedCountryCode" [tooltipField]="TooltipLabel.Name" [phoneValidation]="true"
                              [separateDialCode]="true" [(ngModel)]="phoneNumber" id="phone-id" name="contactPhone"
                              formControlName="contactPhone"></ngx-intl-tel-input>
          <div *ngIf="submitted && (f.contactPhone.errors || contactPhoneLengthError)" class="invalid-feedback">
              <!-- <div *ngIf="!f.contactPhone.touched && !pickupAddress.contactPhone">{{'Contact Phone is required'|translate}}</div> -->
              <div *ngIf="f.contactPhone.invalid">{{'Invalid Phone number'|translate}}</div>
          </div>
      </div>
      <div class="input-wrapper">
          <label class="m-0 f-bold">{{'Additional Delivery Instruction' | translate}}</label>
          <input type="text" (change)="storeAddress()" [(ngModel)]="pickupAddress.instructions" class=""
                 placeholder="{{'Enter detailed description of delivery address (optional)' | translate}}"
                 formControlName="instructions">
      </div>
  </div>
</form>

<app-country-selector (countryChange)="onCountryChange($event)"></app-country-selector>

