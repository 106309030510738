import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, CartItem, AppEventType } from '../models';
import { CartService } from '../cart.service';
import { Cart } from '../cart';
import { ExtraCategoryAlertComponent } from '../extra-category-alert/extra-category-alert.component';
import * as _ from 'lodash'
import { UtilsService } from '../utils.service';
import { ApiService } from '../api.service';
import { TranslateService } from '@ngx-translate/core';
import { LangChangeEvent } from '@ngx-translate/core/lib/translate.service';

@Component({
    selector: 'app-menu-item-card',
    templateUrl: './menu-item-inline-card.component.html',
    styleUrls: ['./menu-item-inline-card.component.css']
})
export class MenuItemInlineCardComponent implements OnInit {

    @Input() item: MenuItem
    @Input() checkExtraCategory: Boolean = false
    @Input() extraChargeAlert: ExtraCategoryAlertComponent
    cart: Cart
    limit: number = 65;
    isContentToggled: boolean;
    completeWords: boolean = true;
    conentLength = 0;
    ngOnInit() {

        this.trans.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
            console.log("listen");
            console.log(langChangeEvent.lang);
            this.isContentToggled = false;
            this.conentLength = this.trans.currentLang == 'ar' ? this.item.itemInfo.ingredients.ar.length : this.item.itemInfo.ingredients.en.length;
        });
        this.conentLength = this.trans.currentLang == 'ar' ? this.item.itemInfo.ingredients.ar.length : this.item.itemInfo.ingredients.en.length;
    }

    constructor(private router: Router, private srvCart: CartService, private utils: UtilsService, private api: ApiService, private trans: TranslateService) {
        this.cart = srvCart.getCart();
    }
    onItemClickClick(cat: any) {
        if (cat.subCategories && cat.subCategories.length) this.router.navigate(['/sub-category/' + cat.id]);
        else this.router.navigate(['/categories/' + cat.categoryInfo.id + '/items']);
    }




    checkOutOfStock(item) {
        return this.srvCart.isOutOfStock(item);
    }
    addItem(item: MenuItem) {
        this.utils.CheckStateAndContinue(this.cart.$truck.lastKnowState, (canAddToCart) => {
            if (!canAddToCart)
                return;
            if (!this.srvCart.getCart().serviceType_ex) {
                this.router.navigate(['/service']);
                return;
            }
            this.checkExtraCartCat(item);
            let mustSelect = _.some(item.extras, d => d.extraCategory.minQty > 0)
            if (mustSelect) {
                this.router.navigate([`/item/${item.itemInfo.id}`]);
                return;
            }
            var found = _.find(this.cart.cartItems, c => c.$item.id == item.itemInfo.id);
            let oldQty = 0;
            if (found)
                oldQty = found.qty;
            if (!this.srvCart.validateItemToAdd(item, oldQty, 1, item.itemInfo.price))
                return;

            if (found) {
                found.qty += 1;
            } else {
                this.srvCart.addItem(_.assignIn(new CartItem(), {
                    $item: item.itemInfo,
                    extras: [],
                    qty: 1,
                    notes: "",
                }));
            }
            item.qty += 1;
            this.utils.fbTrack('AddToCart');
            this.utils.snapchatTrack('ADD_CART');
            this.api.trackEvent(AppEventType.AddToCart, item.itemInfo.id, { qty: item.qty });
            this.srvCart.saveCart();
            this.utils.toaster.success(this.trans.instant('Added to cart successfully'));
        });

    }
    removeItem(item: MenuItem) {
        var found = _.find(this.cart.cartItems, c => c.$item.id == item.itemInfo.id);
        if (found) {
            found.qty -= 1;
            item.qty -= 1;
            if (found.qty == 0) {
                this.srvCart.removeCartItem(found);
            }
        }
        this.srvCart.saveCart();
    }
    checkExtraCartCat(item) {
        let _self = this;
        let category = this.srvCart.getCart().baseCategory(item.itemInfo.id);
        if (this.cart.cartItems.length != 0) {
            let foundCat = _.find(this.cart.cartItems, ci => this.getCatId(ci.$item.id) == category.categoryInfo.id);
            if (!foundCat &&
                ((category.categoryInfo.extraDeliveryCharge &&
                    category.categoryInfo.extraDeliveryCharge != 0) ||
                    (category.categoryInfo.extraDeliveryTimeMinutes &&
                        category.categoryInfo.extraDeliveryTimeMinutes != 0)
                ))
                setTimeout(() => this.extraChargeAlert && this.extraChargeAlert.selectExtraCatAlert(() => { }, category));
        }
    }

    getCatId(itemId) {
        var baseCat = this.cart.baseCategory(itemId);
        if (baseCat)
            return baseCat.categoryInfo.id
    }

    toggleContent() {
        this.isContentToggled = !this.isContentToggled;
    }

    formatContent(content: string) {
        if (this.isContentToggled) {
            return content;
        }
        return `${content.substr(0, this.limit)}...`;

    }

    goToItemDetails(item: any) {
        this.router.navigate(['/item/' + item?.itemInfo.id]);
    }

}
